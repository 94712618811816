<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="500"
      :persistent="load"
    >
      <v-card
        flat
        :loading="load"
        :disabled="load"
      >
        <v-card-title>
          <v-app-bar
            flat
          >
            <v-toolbar-title>
              <div v-if="!$vuetify.breakpoint.mobile"><v-icon class="ma-2" small>fas fa-sync</v-icon>Sincronizar Reservas de HQ</div>
              <div v-else><v-icon class="ma-2" small>fas fa-sync</v-icon>Sync HQ</div>
            </v-toolbar-title>
            <v-checkbox
              v-model="todas"
              color="info"
              hide-details
              :true-value="1"
              :false-value="0"
              class="ml-1"
              label="Todas"
              @change="reservaId = null"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
          </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col :cols="$vuetify.breakpoint.mobile ? '9' : '12'" sm="9" md="12" class="py-1">
              <v-text-field
                v-model="reservaId"
                outlined
                dense
                type="number"
                label="Reserva a Sincronizar"
                hide-details
                clearable
                :readonly="todas == 1"
                :disabled="todas == 1"
                :filled="todas == 1"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3" class="pt-1" align="right" v-if="$vuetify.breakpoint.mobile">
              <BtnConfirmar 
                :texto="cadenaVacia(reservaId) ? `¿Está seguro de Sincronizar la información de HQ?` : `¿Está seguro de Sincronizar la Reserva ${reservaId} de HQ?`"
                @action="sincronizar()"
                color="success"
                nombre="Sincronizar"
                icono="fas fa-sync"
                :clase="$vuetify.breakpoint.mobile ? 'ml-2' : ''"
                :icon_button="true"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center" v-if="!$vuetify.breakpoint.mobile">
          <!-- <v-btn color="success" small><v-icon small left>fas fa-sync</v-icon>Sincronizar</v-btn> -->
          <BtnConfirmar 
            :texto="cadenaVacia(reservaId) ? `¿Está seguro de Sincronizar la información de HQ?` : `¿Está seguro de Sincronizar la Reserva ${reservaId} de HQ?`"
            @action="sincronizar()"
            color="success"
            nombre="Sincronizar"
            icono="fas fa-sync"
            :clase="$vuetify.breakpoint.mobile ? 'ml-2' : ''"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
import { cadenaVacia } from '../../util/utils';
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  components: {
    BtnConfirmar
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModSyncRes', value)
      }
    },
  },
  data() {
    return {
      reservaId: '',
      load: false,
      cadenaVacia: cadenaVacia,
      todas: 0
    }
  },
  methods: {
    clearForm(){
      this.reservaId = ''
      this.todas = 0
    },
    async sincronizar(){
      this.load = true
      let syncPeticion
      if (cadenaVacia(this.reservaId)) syncPeticion = await this.$store.dispatch('reservas/syncHQInfo')
      else syncPeticion = await this.$store.dispatch('reservas/syncResHQInfo', {reserva_id: this.reservaId})
      this.load = false
      if (syncPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: syncPeticion.msj,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Sincronización realizada con éxito.',
        color: 'success',
      })
      this.$emit('getReservas')
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.clearForm()
    }
  },
}
</script>

<style>

</style>